import styled from "styled-components";

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ButtonContainer = styled.button`
  border: 2px solid #6a9946;
  background: linear-gradient(to right, #70c034, #58942a, #2f4e16);
  font-family: "Gotham-Black";
  color: white;
  cursor: pointer;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    scale: 1.1;
    filter: brightness(1.1);
  }

  &:disabled {
    background: linear-gradient(to right, #766253, #5f534b, #332b25);
    color: #a1a1a1;
    border: 2px solid #54493e;
  }
`;

const ButtonDepositContainer = styled.button`
  background: #70c034;
  font-family: "Gotham-Medium";
  color: #232426;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    scale: 1.1;
    filter: brightness(1.1);
  }

  &:disabled {
    background: linear-gradient(to right, #766253, #5f534b, #332b25);
    color: #a1a1a1;
    border: 2px solid #54493e;
  }
`;

export const Button = ({ ...props }: ButtonProps) => {
  return <ButtonContainer {...props} />;
};

export const ButtonDeposit = ({ ...props }: ButtonProps) => {
  return <ButtonDepositContainer {...props} />;
};
