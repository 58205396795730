import styled from "styled-components";
import { ButtonDeposit } from "../Button";
import { Offer } from "../Offer";

const ModalContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .modal-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-layout {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 95%;
    max-width: 500px;
    background: #232426;
    z-index: 20;
    border-radius: 1.5rem;

    h3 {
      font-family: "Gotham-Black";
      font-size: 2rem;
      text-transform: uppercase;
      background: linear-gradient(90deg, #0075cd, #8bcdff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .offer-item {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      border: 1px solid #fff;
      border-radius: 8px;
      padding: 1rem 0;
      gap: 8px;
      max-width: 500px;

      img {
        width: 24px;
        height: 27px;
      }

      p {
        font-size: 1.25rem;
        font-family: "Gotham-Medium";
      }

      strong {
        font-size: 1.25rem;
        font-family: "Gotham-Black";
      }

      span {
        font-size: 1.25rem;
        font-family: "Gotham-Medium";
      }

      @media (max-width: 768px) {
        p {
          font-size: 1rem;
        }

        strong {
          font-size: 1rem;
        }

        span {
          font-size: 1rem;
        }
      }

      @media (max-width: 425px) {
        p {
          font-size: 0.875rem;
        }

        strong {
          font-size: 0.875rem;
        }

        span {
          font-size: 0.875rem;
        }
      }

      @media (max-width: 425px) {
        p {
          font-size: 0.75rem;
        }

        strong {
          font-size: 0.75rem;
        }

        span {
          font-size: 0.75rem;
        }
      }
    }

    .offer-container {
      @media (max-width: 320px) {
        margin-bottom: 1.25rem;
      }
    }

    .modal-button {
      @media (max-width: 320px) {
        padding: 1rem;
        * {
          font-size: 0.75rem;
        }
      }
    }
  }
`;

export const Modal = () => {
  return (
    <ModalContainer className="z-20 ">
      <div className="modal-bg" />
      <div className="modal-layout text-white ">
        <div className="w-full">
          <img
            alt="you-got-rounds"
            className="w-full"
            src="https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/0ef69724-9bd5-4822-8991-7252b0bd6b00/public"
          />
        </div>

        <div className="offer-container pt-3 px-4 flex flex-col items-center mb-8 w-full gap-3">
          <div className="offer-item">
            <img
              src="https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/34c84cdf-034b-4d53-b070-3281436fe200/public"
              alt=""
            />
            <strong>20 Rodadas Grátis</strong>
            <span>|</span>
            <p>Deposite R$10</p>
          </div>

          <div className="offer-item">
            <img
              src="https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/34c84cdf-034b-4d53-b070-3281436fe200/public"
              alt=""
            />
            <strong>40 Rodadas Grátis</strong>
            <span>|</span>
            <p>Deposite R$20</p>
          </div>

          <div className="offer-item">
            <img
              src="https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/34c84cdf-034b-4d53-b070-3281436fe200/public"
              alt=""
            />
            <strong>100 Rodadas Grátis</strong>
            <span>|</span>
            <p>Deposite R$50</p>
          </div>
        </div>

        <div className="flex w-full justify-center">
          <div className="flex gap-5 items-cecnter">
            <ButtonDeposit className="modal-button py-2 uppercase mb-2 px-3 text-lg font-semibold gap-2 max-w-none">
              <Offer>
                <p>depositar</p>
              </Offer>
            </ButtonDeposit>
            <Offer>
              <span
                className="font-bold text-gray-500"
                style={{ fontSize: "18px" }}
              >
                Fechar
              </span>
            </Offer>
          </div>
        </div>

        <img
          alt=""
          className="max-w-[186px] my-4 mb-8"
          src="https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/7fb55291-027b-4636-d5f1-02157978ab00/public"
        />
      </div>
    </ModalContainer>
  );
};
