import { useTimer } from 'react-timer-hook'

export function Timer({ expiryTimestamp }: { expiryTimestamp: Date }) {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
  })

  return (
    <>
      {minutes}:{seconds < 10 ? '0' + seconds : seconds}
    </>
  )
}
